/**
 * 後台回傳多語言欄位的解析方法
 */
export default {
  install (Vue, options) {
    Vue.mixin({
      computed: {
        /**
         * 依序目前系統語系，顯示多語言欄位的值
         *
         * @example
         * ```js
         * const data = {
         *   en: 'Hello',
         *   cn: '你好'
         * }
         * ```
         *
         * ```pug
         * div {{multipleLang(data)}}
         * ```
         */
        multipleLang () {
          return (langFormat: {[locale: string]: string}) => {
            // @ts-ignore
            const lang = this.$store.getters.lang
            return langFormat[lang]
          }
        },
        /**
         * 依序目前系統語系，顯示多語言欄位的值 (取某個特定欄位)
         *
         * @example
         * ```js
         * const data = {
         *   val: 20,
         *   title: {
         *     en: 'Age',
         *     cn: '年龄'
         *   }
         * }
         * ```
         *
         * ```pug
         * // - v-html 範例
         * div {{multipleLang('title')(data)}}
         *
         * // - vuetify item 範例
         * v-select(
         *   :item-label="multipleLang('title')"
         *   item-value="data"
         * )
         * ```
         */
        multipleLangItem () {
          return (index: string) => {
            return (item: any) => {
              // @ts-ignore
              const lang = this.$store.getters.lang
              return item[index][lang]
            }
          }
        }
      }
    })
  }
}
