import Vue from 'vue'

/**
 * 比較大小 ( "輸入值(inputValue)" 不可小於等於 "基準值(referValue)" )
 */
export default function numMoreThan (this: Vue, referValue: number) {
  return (inputValue) => {
    if (!inputValue) {
      return true
    } else if (Number(inputValue) * 1000 <= referValue * 1000) {
      return this.$t('validate.numMoreThan', { referValue }) // 必须大于...
    }
    return true
  }
}
