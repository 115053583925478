import Vue from 'vue'

/**
 * 是否為 url
 */
export default function url (this: Vue, v) {
  const regex = /^(https?:\/\/)[^\s$.?#].[^\s]*$/i
  if (!v) {
    return true
  } else if (regex.test(v)) {
    return true
  }
  return this.$t('validate.url')
}
