import Vue from 'vue'

/**
 * 比較大小 ( "輸入值(inputValue)" 不可大於等於 "基準值(referValue)" )
 */
export default function numLessThan (this: Vue, referValue: number) {
  return (inputValue) => {
    if (!inputValue) {
      return true
    } else if (Number(inputValue) * 1000 >= referValue * 1000) {
      return this.$t('validate.numLessThan', { referValue }) // 必须小于...
    }
    return true
  }
}
